y<template>
  <div>
    <!-- <div class="loader" v-if="this.isLoading"></div>
    <div class="loadertext" v-if="this.isLoading">
      {{ $t("loadingTxt") }}...
    </div> -->
    <div class="main-wrapper">
      <!--begin::sidebar-->
      <sidebar-component classMenu="BranchAdd"></sidebar-component>
      <!--end::sidebar-->

      <div class="page-wrapper">
        <!--begin::navbar-->
        <navbar-component></navbar-component>
        <!--end::navbar-->

        <!--begin::content-->
        <div class="page-content">
          <div
            class="d-flex justify-content-between align-items-center flex-wrap grid-margin"
          >
            <div class="col-lg-12 col-xl-12 col-sm-12 col-xs-12 col-md-12">
              <div class="card">
                <div class="card-header">
                  <!-- <i class="link-icon float-start" data-feather="arrow-left"></i> -->
                  &nbsp;&nbsp;&nbsp;
                  <h6
                    class="card-title mb-0 float-start"
                    style="padding-left: 25px"
                  >
                    Master Ujo {{ $route.query.params }}
                  </h6>
                  <button
                    class="btn btn-default btn-sm float-end"
                    @click="resetForm"
                    type="button"
                  >
                    <i class="link-icon" data-feather="repeat"></i>
                    {{ $t("resetFormTxt") }}
                  </button>
                </div>
                <div class="card-body">
                  <!--begin::loading-data-->
                  <!-- <div v-if="isLoading" class="d-flex align-items-center">
                    <div
                      class="spinner-border ms-auto"
                      role="status"
                      aria-hidden="true"
                    ></div>
                    <strong>{{ $t("loadingTxt") }}...</strong>
                  </div> -->
                  <!--end::loading-data-->

                  <form
                    class="forms-sample"
                    @submit.prevent="submitData"
                    method="POST"
                    ref="formElement"
                  >
                    <div
                      class="d-flex justify-content-between align-items-baseline mb-2"
                    ></div>

                    <div
                      class="row mb-3 align-items-center justify-content-center"
                    >
                      <div
                        class="table-responsive"
                        style="max-height: 800px; min-height: 300px"
                      >
                        <button
                          class="btn btn-success mb-3 pull-right btn-sm"
                          type="button"
                          @click="addField"
                        >
                          {{ $t("CreateTxt") }}
                        </button>
                        <table class="table table-bordered">
                          <thead>
                            <tr>
                              
                              <th style="width: 5%">No</th>
                              <th>Rute</th>
                              <th>Pick State</th>
                              <th>Drop State</th>
                              <th>Route State</th>
                              <th style="width: 2%">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(item, index) in RouteList" :key="index">
                             
                              <td>{{ index + 1 }}</td>
                              <td>
                                <multiselect
                                  @search-change="asyncRoute"
                                  v-model="item.route"
                                  :options="fetchRoute"
                                  @input="assignRoute(index, RouteList, $event)"
                                  label="route_name"
                                  track-by="route_name"
                                  placeholder="Please Select Route"
                                >
                                  <span slot="noResult">-</span>
                                </multiselect>
                              </td>
                              <td>{{ item.pick_state }}</td>
                              <td>{{ item.drop_state }}</td>
                              <td>{{ item.route_state }}</td>
                              <td>
                                
                                <a
                                  href="#"
                                  title="Delete"
                                  @click.prevent="removeField(index, RouteList)"
                                >
                                  <i
                                    class="mdi mdi-trash-can-outline"
                                    style="font-size: 16px"
                                  ></i>
                                </a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <div class="col-sm-4">

                        <div class="row mb-3">
                            <div class="col-lg-4">
                                <label for="defaultconfig" class="col-form-label" style="padding-left: 25px;">Type Kalkulasi</label>
                            </div>
                            <div class="col-lg-8 d-flex justify-content-between">
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" id="radioA" v-model="forms.type_kalkulasi" value="AUTO" @change="handleChangeKalkulasi">
                                    <label class="form-check-label" for="radioA">AUTO</label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" id="radioB" v-model="forms.type_kalkulasi" value="MANUAL" @change="handleChangeKalkulasi">
                                    <label class="form-check-label" for="radioB">MANUAL</label>
                                </div>
                            </div>
                        </div>

                        <div class="row mb-3">
                            <div class="col-lg-4">
                                <label for="defaultconfig" class="col-form-label" style="padding-left: 25px;">Type UJO</label>
                            </div>
                            <div class="col-lg-8 d-flex justify-content-between">
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" id="radioD" v-model="forms.type_ujo" value="A" @change="handleChange">
                                    <label class="form-check-label" for="radioA">A</label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" id="radioD" v-model="forms.type_ujo" value="B" @change="handleChange">
                                    <label class="form-check-label" for="radioB">B</label>
                                </div>
                            </div>
                        </div>


                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px;"
                              >Status</label
                            >
                          </div>
                          <div class="col-lg-8">
                         
                            <v-select
                              :options="fetchStatus"
                              v-model="forms.status"
                              label="status"
                              return-object
                              disabled
                            ></v-select>
                          </div>
                         
                        </div>



                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px;"
                              >Leadtime</label
                            >
                          </div>
                          <div class="col-lg-8">
                           
                            <input  :placeholder='$t("days")'   class="form-control" type="text" v-model="forms.lead_time" id="exampleCheckbox">
                          </div>
                         
                        </div>


                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px;"
                              >{{ $t("customer") }}</label
                            >
                          </div>
                          <div class="col-lg-8">
                            <multiselect
                              @search-change="asyncCustomer"
                              v-model="customer"
                              :options="fetchCustomer"
                              label="name"
                              track-by="name"
                              :disabled="disabledCs"
                              placeholder="Please Select Customer"
                              @input="changeCompany()"
                            >
                              <span slot="noResult">-</span>
                            </multiselect>
                            <input placeholder="code"  class="form-control" readonly type="text" v-model="forms.customer_code" id="exampleCheckbox">
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >{{ $t("branchTxt") }}</label
                            >
                          </div>
                          <div class="col-lg-8">
                            <multiselect
                              @search-change="asyncBranch"
                              v-model="branch"
                              :options="fetchBranch"
                              label="branch_name"
                              track-by="branch_name"
                              placeholder="Please Select Branch"
                              @input="loadRatio()"
                            >
                              <span slot="noResult">-</span>
                            </multiselect>
                            <div v-if="errors.branch_name">
                              <div
                                v-for="error in errors.branch_name"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >{{ $t("type_truck") }}</label
                            >
                          </div>
                          <div class="col-lg-8">
                            <multiselect
                              @search-change="asyncTypeTruck"
                              v-model="typeTruck"
                              :options="fetchTypeTruck"
                              label="type_truck"
                              track-by="type_truck"
                              placeholder="Please Select Route"
                              @input="loadRatio()"
                            >
                              <span slot="noResult">-</span>
                            </multiselect>
                            <div v-if="errors.type_truck">
                              <div
                                v-for="error in errors.type_truck"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-3">

                          <div class="col-lg-4">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >Effective Date</label
                            >
                          </div>
                          <div class="col-lg-8">
                            <date-range-picker
                              style="width: 100%"
                              control-container-class="form-control"
                              :locale-data="locale"
                              v-model="effective_date"
                              opens="center"
                              :singleDatePicker="true"
                              :timePicker24Hour="true"
                              :timePicker="true"
                              :showDropdowns="true"
                              :showWeekNumbers="false"
                            >
                            </date-range-picker>

                          

                            <!-- <input type="text" id="order_date" name="order_date" v-model="forms.order_date" class="form-control" placeholder="Order Date"> -->
                            <div
                              v-for="error in errors.effective_date"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>


                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >Expired Date</label
                            >
                          </div>
                          <div class="col-lg-8">
                            <date-range-picker
                              style="width: 100%"
                              control-container-class="form-control"
                              :locale-data="locale"
                              v-model="expired_date"
                              opens="center"
                              :singleDatePicker="true"
                              :timePicker24Hour="true"
                              :timePicker="true"
                              :showDropdowns="true"
                              :showWeekNumbers="false"
                            >
                            </date-range-picker>
                            <!-- <input type="text" id="order_date" name="order_date" v-model="forms.order_date" class="form-control" placeholder="Order Date"> -->
                            <div
                              v-for="error in errors.expired_date"
                              :key="error"
                              class="alert alert-primary"
                              role="alert"
                            >
                              <i data-feather="alert-circle"></i>
                              {{ error }}
                            </div>
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >Master Ujo Name
                              <span class="wajib">*</span></label
                            >
                          </div>
                          <div class="col-lg-8">
                            <input
                              type="text"
                              class="form-control"
                              v-model="forms.name"
                              placeholder="Master Ujo Name"
                              required
                              readonly
                            />
                            <div v-if="errors.name">
                              <div
                                v-for="error in errors.name"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >{{ $t("distance") }} (Km)</label
                            >
                          </div>
                          <div class="col-lg-8">
                            <input
                              type="number"
                              class="form-control"
                              :value="getDistance"
                              disabled
                              :placeholder="this.$t('distance')"
                            />
                            <div v-if="errors.distance">
                              <div
                                v-for="error in errors.distance"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >Rasio</label
                            >
                          </div>
                          <div class="col-lg-8">
                            <multiselect
                              @search-change="asyncRatio"
                              v-model="ratioDetail"
                              :options="fetchRatioDetail"
                              label="name"
                              track-by="name"
                              placeholder="Please Select Ratio"
                              @input="changeMasterName()"
                            >
                              <span slot="noResult">-</span>
                            </multiselect>
                            <div v-if="errors.ratio_master_name">
                              <div
                                v-for="error in errors.ratio_master_name"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >Rasio Detail <span class="wajib">*</span></label
                            >
                          </div>
                          <div class="col-lg-6">
                            <multiselect
                              v-model="ratioDetail"
                              :options="fetchRatioDetail"
                              label="name"
                              track-by="name"
                              placeholder="Please Select Detail Ratio"
                            >
                              <span slot="noResult">-</span>
                            </multiselect>
                            <div v-if="errors.ratio_master_name">
                              <div
                                v-for="error in errors.ratio_master_name"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div> -->
                        
                        <!-- <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="working_day"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >{{ $t("masterUjoWorkingDay") }}</label
                            >
                          </div>
                          <div class="col-lg-6">
                            <input
                              type="text"
                              class="form-control"
                              id="working_day"
                              :placeholder="$t('masterUjoWorkingDay')"
                              v-model="forms.lead_time"
                              required
                            />
                            <div v-if="errors.lead_time">
                              <div
                                v-for="error in errors.lead_time"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div> -->
                      </div>

                      <div class="col-sm-4">


                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >Solar ( Liter )</label
                            >
                          </div>
                          <div class="col-lg-8">
                            <input
                              type="number"
                              class="form-control"
                              placeholder="Solar (Liter)"
                              v-model.number="forms.fuel_liter"
                              step="any"
                            />
                            <div v-if="errors.fuel_liter">
                              <div
                                v-for="error in errors.fuel_liter"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >Solar ( Rp )</label
                            >
                          </div>
                          <div class="col-lg-8">
                            <!-- v-model.number="forms.fuel_price" -->
                            <!-- <input
                              type="number"
                              class="form-control"
                              placeholder="Solar (Rp)"
                              :value="getSolarPrice"
                              disabled
                            /> -->
                            <CurrencyInput
                              :value="getSolarPrice"
                              :options="{ currency: 'IDR' }"
                              class="form-control"
                              placeholder="Solar (Rp)"
                              disabled
                              v-if="this.forms.type_kalkulasi=='AUTO'"
                            />

                            <CurrencyInput
                               v-model.number="forms.fuel_price"
                              
                              :options="{ currency: 'IDR' }"
                              class="form-control"
                              placeholder="Solar (Rp)"
                             
                              v-if="this.forms.type_kalkulasi=='MANUAL'"
                            />

                            <div v-if="errors.fuel_price">
                              <div
                                v-for="error in errors.fuel_price"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>
                       
                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="working_day"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >{{ $t("masterUjoPay") }}</label
                            >
                          </div>
                          <div class="col-lg-8">
                            <CurrencyInput
                              :value="getTotalSalary"
                              
                              :options="{ currency: 'IDR' }"
                              class="form-control"
                              :placeholder="$t('masterUjoPay')"
                             v-if="this.forms.type_kalkulasi=='AUTO'"
                             disabled
                            />

                            <CurrencyInput
                              
                              v-model.number="forms.salary"
                              :options="{ currency: 'IDR' }"
                              class="form-control"
                              :placeholder="$t('masterUjoPay')"
                               v-if="this.forms.type_kalkulasi=='MANUAL'"
                            />
                             
                            <!-- <CurrencyInput
                              :value="forms.salary"
                              :options="{ currency: 'IDR' }"
                              class="form-control"
                              :placeholder="$t('masterUjoPay')"
                              disabled
                            /> -->
                            <!-- <input
                              type="number"
                              class="form-control"
                              id="salary"
                              :placeholder="$t('masterUjoPay')"
                              v-model.number="forms.salary"
                              disabled
                            /> -->
                            <div v-if="errors.salary">
                              <div
                                v-for="error in errors.salary"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="working_day"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >Self Insurance</label
                            >
                          </div>
                          <div class="col-lg-8">
                            <CurrencyInput
                              v-model.number="forms.self_insurance"
                              :options="{ currency: 'IDR' }"
                              class="form-control"
                              placeholder="Self Insurance"
                              disabled
                            />
                           
                            <div v-if="errors.self_insurance">
                              <div
                                v-for="error in errors.self_insurance"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="working_day"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >Deposit</label
                            >
                          </div>
                          <div class="col-lg-6">
                            <CurrencyInput
                              :value="getTotalDeposit"
                              :options="{ currency: 'IDR' }"
                              class="form-control"
                              disabled
                            />
                            <!-- <CurrencyInput
                              :value="forms.deposit"
                              :options="{ currency: 'IDR' }"
                              class="form-control"
                              disabled
                            /> -->
                            <!-- <input
                              type="text"
                              class="form-control"
                              id="fee"
                              :placeholder="$t('masterUjoTolFee')"
                              :value="
                                forms.toll_price
                                  ? forms.toll_price
                                  : 0 | format_number
                              "
                              disabled
                            /> -->
                            <div v-if="errors.deposit">
                              <div
                                v-for="error in errors.deposit"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>


                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="working_day"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >Rute Tol</label
                            >
                          </div>
                          <div class="col-lg-6">
                            <input
                              type="text"
                              class="form-control"
                              id="fee"
                              :placeholder="$t('masterUjoPay')"
                              :value="toll_route?.toll_route_code"
                              disabled
                            />
                            <div v-if="errors.toll_route_name">
                              <div
                                v-for="error in errors.toll_route_name"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-1">
                            <button
                              :disabled="this.ratio == null || this.ratio == ''"
                              type="button"
                              @click="pickTol"
                              class="btn btn-sm btn-success"
                            >
                              Pilih
                            </button>
                          </div>
                        </div>

                       
                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="working_day"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >{{ $t("masterUjoTolFee") }} (Rupiah)</label
                            >
                          </div>
                          <div class="col-lg-6">
                            <CurrencyInput
                              v-model.number="forms.toll_price"
                              :options="{ currency: 'IDR' }"
                              class="form-control"
                              :placeholder="$t('masterUjoTolFee')"
                              disabled
                            />
                            <!-- <input
                              type="text"
                              class="form-control"
                              id="fee"
                              :placeholder="$t('masterUjoTolFee')"
                              :value="
                                forms.toll_price
                                  ? forms.toll_price
                                  : 0 | format_number
                              "
                              disabled
                            /> -->
                            <div v-if="errors.toll_price">
                              <div
                                v-for="error in errors.toll_price"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>


                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="working_day"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >Mel</label
                            >
                          </div>
                          <div class="col-lg-8">
                           
                             <CurrencyInput
                              v-model.number="forms.mel"
                              :options="{ currency: 'IDR' }"
                              class="form-control"
                              placeholder="Mel"
                            />
                            <div v-if="errors.mel">
                              <div
                                v-for="error in errors.mel"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>


                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >{{ $t("nstBiayaBongkarTxt") }}</label
                            >
                          </div>
                          <div class="col-lg-6">
                            <CurrencyInput
                              v-model.number="forms.nst_biaya_bongkar"
                              :options="{ currency: 'IDR' }"
                              class="form-control"
                              placeholder="0.01"/>

                           
                            <div v-if="errors.nst_biaya_bongkar">
                              <div
                                v-for="error in errors.nst_biaya_bongkar"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>

                          <div class="col-lg-2">
                            <button
                              @click="rateBreak()"
                              type="button"
                              class="btn btn-sm btn-success"
                            >
                              <i class="mdi mdi-currency-usd"></i>
                            </button>
                          </div>

                        </div>


                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >{{ $t("nstInsentifRit1Txt") }}</label
                            >
                          </div>
                          <div class="col-lg-8">
                            <CurrencyInput
                              v-model.number="forms.nst_insentif_rit1"
                              :options="{ currency: 'IDR' }"
                              class="form-control"
                              placeholder="0.01"/>

                          
                            <div v-if="errors.nst_insentif_rit1">
                              <div
                                v-for="error in errors.nst_insentif_rit1"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                     
                      
                        <!-- <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >Total Base Rate</label
                            >
                          </div>
                          <div class="col-lg-6">
                            <CurrencyInput
                              :value="getTotalUjo"
                              :options="{ currency: 'IDR' }"
                              class="form-control"
                              placeholder="Total Ujo"
                              disabled
                            />
                            <div v-if="errors.ujo_total">
                              <div
                                v-for="error in errors.ujo_total"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >{{ $t("totalRoundingTxt") }}</label
                            >
                          </div>
                          <div class="col-lg-6">
                            <CurrencyInput
                              :value="getUjoRounding"
                              :options="{ currency: 'IDR' }"
                              class="form-control"
                              :placeholder="$t('totalRoundingTxt')"
                              disabled
                            />
                            <div v-if="errors.ujo_total_rounding">
                              <div
                                v-for="error in errors.ujo_total_rounding"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div> -->
                      </div>


                      <div class="col-sm-4">


                       

                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >{{ $t("nstKomisiSupirTxt") }}</label
                            >
                          </div>
                          <div class="col-lg-8">
                            <CurrencyInput
                              v-model.number="forms.nst_komisi_supir"
                              :options="{ currency: 'IDR' }"
                              class="form-control"
                              placeholder="0.01"/>
                            <!-- <input
                              type="number"
                              class="form-control"
                             
                              v-model.number="forms.nst_komisi_supir"
                              step="any"
                            /> -->
                            <div v-if="errors.nst_komisi_supir">
                              <div
                                v-for="error in errors.nst_komisi_supir"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>
                       
                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >{{ $t("nstBiayaTerpalTxt") }}</label
                            >
                          </div>
                          <div class="col-lg-8">
                            <CurrencyInput
                              v-model.number="forms.nst_biaya_terpal"
                              :options="{ currency: 'IDR' }"
                              class="form-control"
                              placeholder="0.01"/>
                            <!-- <input
                              type="number"
                              class="form-control"
                             
                              v-model.number="forms.nst_biaya_terpal"
                              step="any"
                            /> -->
                            <div v-if="errors.nst_biaya_terpal">
                              <div
                                v-for="error in errors.nst_biaya_terpal"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >{{ $t("CrossTxt") }} </label
                            >
                          </div>
                          <div class="col-lg-8">
                            <p
                              v-for="(item, index) in penyebrangan"
                              :key="index"
                            >
                              {{ item.nama }} : {{ item.harga }}
                            </p>
                          </div>
                        </div> 
                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >{{ $t("CrossFeeTxt") }} ( Rp )</label
                            >
                          </div>
                          <div class="col-lg-8">
                            <CurrencyInput
                              :value="getBiayaPenyebrangan"
                              :options="{ currency: 'IDR' }"
                              class="form-control"
                              disabled
                              :placeholder="$t('CrossFeeTxt')"
                             
                            />

                            <!-- <input
                              type="text"
                              class="form-control"
                              id="fee"
                              :placeholder="$t('CrossFeeTxt')"
                              :value="getBiayaPenyebrangan | format_number"
                              disabled
                              step="any"
                            /> -->
                            <div v-if="errors.ferry">
                              <div
                                v-for="error in errors.ferry"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>


                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >NST MEL</label
                            >
                          </div>
                          <div class="col-lg-8">
                            <CurrencyInput
                              v-model.number="forms.nst_mel"
                              :options="{ currency: 'IDR' }"
                              class="form-control"
                              placeholder="Solar (Rp)"
                             
                            />
                           
                            <div v-if="errors.nst_mel">
                              <div
                                v-for="error in errors.nst_mel"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>


                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >NST REKON</label
                            >
                          </div>
                          <div class="col-lg-8">
                            <CurrencyInput
                              v-model.number="forms.nst_rekon"
                              :options="{ currency: 'IDR' }"
                              class="form-control"
                              placeholder="0.01"
                             
                            />
                           
                            <div v-if="errors.nst_rekon">
                              <div
                                v-for="error in errors.nst_rekon"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>


                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >Total UJO</label
                            >
                          </div>
                          <div class="col-lg-6">
                            <CurrencyInput
                              :value="getTotalUjo"
                              :options="{ currency: 'IDR' }"
                              class="form-control"
                              placeholder="Total Ujo"
                              disabled
                            />
                            <div v-if="errors.ujo_total">
                              <div
                                v-for="error in errors.ujo_total"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >{{ $t("totalRoundingTxt") }}</label
                            >
                          </div>
                          <div class="col-lg-6">
                            <CurrencyInput
                              :value="getUjoRounding"
                              :options="{ currency: 'IDR' }"
                              class="form-control"
                              :placeholder="$t('totalRoundingTxt')"
                              disabled
                            />
                            <div v-if="errors.ujo_total_rounding">
                              <div
                                v-for="error in errors.ujo_total_rounding"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>


                        <div class="row mb-3">
                            <div class="col-lg-4">
                                <label for="defaultconfig" class="col-form-label" style="padding-left: 25px;">Round Trip</label>
                            </div>
                            <div class="col-lg-8 d-flex justify-content-between">
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" id="radioC" v-model="forms.round_trip" value="YES" >
                                    <label class="form-check-label" for="radioC">Yes</label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" id="radioD" v-model="forms.round_trip" value="NO" >
                                    <label class="form-check-label" for="radioD">No</label>
                                </div>
                            </div>
                        </div>



                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >{{ $t("alfaKosonganBlTxt") }}</label
                            >
                          </div>
                          <div class="col-lg-8">

                            <CurrencyInput
                              v-model.number="forms.alfa_kosongan_bl"
                              :options="{ currency: 'IDR' }"
                              class="form-control"
                              placeholder="0.01"/>


                           
                            <div v-if="errors.alfa_kosongan_bl">
                              <div
                                v-for="error in errors.alfa_kosongan_bl"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>




                       
                  
                      </div>

                      

                       

                     
                    </div>

                        <div class="row mb-3">
                          
                          <div class="col-lg-12">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >Remarks</label
                            >
                           <textarea class="form-control" name="remarks" v-model="forms.remarks"></textarea>

                           
                            <div v-if="errors.remarks">
                              <div
                                v-for="error in errors.remarks"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                    
                    <div class="card-footer">
                      <button
                        class="btn btn-warning text-black fw-bolder btn-icon-text"
                        @click="backForm"
                        type="button"
                      >
                        <i class="link-icon" data-feather="arrow-left"></i>
                        {{ $t("backMess") }}
                      </button>
                      &nbsp;&nbsp;&nbsp;
                      <button v-if="!isLoading"
                        class="btn btn-primary float-end btn-sm"
                        type="submit"
                      >
                        <i class="link-icon" data-feather="save"></i>
                        {{ $t("submitFormTxt") }}
                      </button>


                      <div v-if="isLoading" class="d-flex align-items-center float-end">
                        <div
                          class="spinner-border ms-auto"
                          role="status"
                          aria-hidden="true"
                        ></div>
                        <strong>{{ $t("loadingTxt") }}...</strong>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <!--end::card-->
            </div>
            <modal-rute-tol
              :showModal="modalRuteTol"
              :data="modalRouteTolData"
              @closeModal="closeMyModal"
            ></modal-rute-tol>

            <modal-rate-break :showModal="showModalRateBreak" :data="passDataRate" formType="Add"
            @closeModal="closeMyModalRateBreak"></modal-rate-break>

          </div>
        </div>
        <!--end::content-->

        <!--begin::footer-->
        <footer-component></footer-component>
        <!--end::footer-->
      </div>
    </div>
  </div>
</template>

<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";
import multiselect from "vue-multiselect";
import ModalRuteTolUjo from "@/components/modal/modalRuteTolUjo.vue";
import CurrencyInput from '@/components/_partials/inputCurrency.vue';
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import moment from "moment";
import modalRateBreak from "@/components/modal/modalRateBreakUjo.vue";

export default {
  name: "UjoAdd",
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,
    multiselect: multiselect,
    "modal-rute-tol": ModalRuteTolUjo,
    CurrencyInput,
    DateRangePicker,
    "modal-rate-break":modalRateBreak
  },
  data() {
    const startDate = new Date();
    const endDate = new Date();

    return {
      disabledCs:false,
      fetchCustomer:[],
      passDataRate:[],
      showModalRateBreak:false,
      customer:'',
      effective_date: {
        startDate,
        endDate,
      },
      expired_date: {
        startDate,
        endDate,
      },
      fetchStatus:[
        "NEWENABLED",
        "ENABLED",
        "DISABLED",
        "BIDDING" 
      ],
      selectedRow:'',
      isLoading: false,
      modalRuteTol: false,
      maxToasts: 100,
      position: "up right",
      closeBtn: true,
      errors: [],
      poolAll: [],
      permision_role: [],
      userData: "",
      companyCode: "",
      forms: {
        fuel_liter:0,
        salary:0,
        deposit:0,
        lead_time:0,
        status:'NEWENABLED',
        round_trip:"NO",
        toll_price: 0.01
      },

      locale: {
        format: "dd mmm yyyy",
      },
      RouteList: [],
      fetchRoute: [],
      branch: null,
      fetchBranch: [],
      typeTruck: null,
      fetchTypeTruck: [],
      ratio: null,
      fetchRatio: [],
      toll_route: [],
      penyebrangan: [],
      solarPrice: 0,

      ratioDetail: null,
      fetchRatioDetail: [],

      modalRouteTolData:null,
    };
  },
  computed: {
    


    getBiayaPenyebrangan() {
       
        let price = 0.01;

        if (this.penyebrangan && this.penyebrangan.length > 0) { // Check if penyebrangan is not null and has elements
            price = 0;
            this.penyebrangan.forEach(function(item) {

                price += item.harga;
            });
        }

        return price;
    },

    getTotalSalary(){
      let salary = 0;
     
      salary +=this.forms.lead_time * this.forms.salary_awal; 
      console.log(salary); 
      
      return salary;
    },

   
    getTotalDeposit(){
      let deposit = 0;
      let leadtime=this.forms.lead_time;
     
      if(leadtime > 3){
        deposit=this.forms.deposit_awal * 3;
      }else{
        deposit=this.forms.deposit_awal * leadtime;
      }
    
      return deposit;
    },

    // dateFormatOrder(classes, date) {
    //   if (!classes.disabled) {
    //     classes.disabled = date.getTime() + 86400000 < new Date();
    //   }

    //   return classes;
    // },

    getDistance(){
      let distance = 0;
      if(this.RouteList.length > 0){
        if(this.RouteList[0]?.route?.distance_margin){
          distance = this.RouteList[0]?.route.distance_margin;
        }
      }
      return distance;
    },


    
    getTotalUjo() {
      let price = 0;

     


     
    

      if (this.forms.mel) {
        price += parseFloat(this.forms.mel);
      }

      if (this.forms.self_insurance) {
        price += parseFloat(this.forms.self_insurance);
      }

      if (this.forms.nst_biaya_bongkar) {
        price += parseFloat(this.forms.nst_biaya_bongkar);
      }

      // if (this.forms.alfa_kosongan_bl) {
      //   price += parseFloat(this.forms.alfa_kosongan_bl);
      // }

      if (this.forms.nst_insentif_rit1) {
        price += parseFloat(this.forms.nst_insentif_rit1);
      }

      if (this.forms.nst_komisi_supir) {
        price += parseFloat(this.forms.nst_komisi_supir);
      }

      if (this.forms.nst_biaya_terpal) {
        price += parseFloat(this.forms.nst_biaya_terpal);
      }

      if (this.forms.nst_mel) {
        price += parseFloat(this.forms.nst_mel);
      }


      if (this.forms.nst_rekon) {
        price += parseFloat(this.forms.nst_rekon);
      }



      if(this.forms.type_kalkulasi=='MANUAL'){
        if(this.forms.salary){
          price += this.forms.salary;
        }
      }else{
        price += this.getTotalSalary;
      }
     
      price += this.getTotalDeposit;
      // this.forms.salary = this.getTotalSalary();
      // this.forms.deposit = this.getTotalDeposit();

      // price +=this.forms.salary ;
      // price += this.forms.deposit;
      // if (this.forms.salary) {
      //   price += parseFloat(this.forms.salary);
      // }
      if (this.forms.toll_price) {
        price += parseFloat(this.forms.toll_price);
      }
      price += this.getBiayaPenyebrangan;
      
      if(this.forms.type_kalkulasi=='MANUAL'){
        if(this.forms.fuel_price){
          price += this.forms.fuel_price;
        }
      }
      else{
        price += this.getSolarPrice;
      }

      return price;
    },
    getUjoRounding() {
        let val = this.getTotalUjo; // Ambil nilai total
        val = Math.trunc(val); // Hilangkan bagian desimal tanpa membulatkan
        return val; // Kembalikan nilai hasil
    },
    getSolarPrice() {
    let price = 0;
    if (this.forms.fuel_liter) {
      price = this.forms.fuel_liter * this.solarPrice;
    }
    // Rounding
    console.log("Ori Price", price);
    let remainder = price % 1000;
    if (remainder < 500) {
      price = price - remainder;
    } else {
      price = price + (1000 - remainder);
    }
    // console.log("Round Price", price);
    return price;
  },
    getSolarLiter() {
      let price = 0;
      if (this.ratioDetail?.ratio) {
        price = Math.ceil(this.getDistance / this.ratioDetail.ratio);
      }
      return price;
    },
  },
  watch: {
    RouteList:{
      handler(newValue) {
        if(newValue.length > 0){
          if(newValue[0]){
            this.calculateSolarLiter();
          }
        }
      },
      deep:true
    },
    ratioDetail(){
      this.calculateSolarLiter()
    }
  },
  methods: {
    rateBreak() {
 
      this.showModalRateBreak = true;
    },

    calculateSolarLiter() {
      let lt = 0;
      if (this.ratioDetail?.ratio) {
        lt = this.getDistance / this.ratioDetail.ratio;
        console.log(lt);
        // Menggunakan toFixed(2) untuk hasil lebih akurat dengan dua angka desimal
       
      }
      this.forms.fuel_liter = lt;
    },

    loadRoute() {
      const baseURI = this.$settings.endPoint + "rm-route-master";
      this.$http.get(baseURI).then((response) => {
        this.loading();
        this.fetchRoute = response.data.datas.data;
      });
    },

    changeMasterName(){
      console.log(this.ratioDetail.name);
      if(this.RouteList.length > 0 && this.customer!==null){
          let route=this.RouteList[0]?.route_name;
          if(this.ratioDetail.name!='DEFAULT'){
            this.forms.name=route+' '+this.branch.branch_name+ ' '+this.typeTruck.type_truck+' '+this.customer.code+' '+this.ratioDetail.name;
          }else{
            this.forms.name=route+' '+this.branch.branch_name+ ' '+this.typeTruck.type_truck+' '+this.customer.code;
          }
         
      }
      
    },

    
   

    assignRoute(index, fieldType, ev) {
      console.log(ev);
      this.forms.lead_time=ev.leadtime;
      //this.changeLeadTime();
      let error = 0;
      fieldType.forEach(function (field) {
        if (field.rm_route_master_id == ev.rm_route_master_id) {
          error = 1;
        }
      });
      if (error === 0) {
        fieldType[index].rm_route_master_id = ev.rm_route_master_id;
        fieldType[index].route_name = ev.route_name;
        fieldType[index].pick_state = ev.pick_state;
        fieldType[index].drop_state = ev.drop_state;
        fieldType[index].route_state = ev.route_state;
      } else {
        this.removeField(index, fieldType);
        this.error("Duplicate Value !!!");
      }
    },
    triggerRoute(data) {
      this.$set(this.forms, "distance", data.distance_margin);
      //   this.forms.distance = data.distance;
    },
    changeRatio(ev) {
      this.ratioDetail = null;
      this.fetchRatioDetail = [];
      if (ev !== null) {
        this.$set(this.forms, "salary_awal", ev.driver_commision);
        this.$set(this.forms, "self_insurance", ev.driver_self_insurance);
        this.$set(this.forms, "deposit_awal", ev.driver_deposit);
        this.fetchRatioDetail = ev.detail;
      }
    },
    getPenyebrangan(type_truck) {


      this.penyebrangan = [];
      var id = this.RouteList[0].rm_route_master_id;
      const baseURI = this.$settings.endPoint + "rm-ferry-rute/get-ferry/" + id+'?type_truck='+type_truck;
      this.$http.get(baseURI).then((response) => {
        this.penyebrangan = response.data.datas;
      });
    },

    getMel(cat_truck) {
      this.$set(this.forms, "mel", 0.01);
      var pick_state = this.RouteList[0].pick_state;
      var drop_state = this.RouteList[0].drop_state;
      const baseURI = this.$settings.endPoint + "rm-mel-master?truck_category="+cat_truck+"&state_origin="+pick_state+"&state_destination="+drop_state;
      this.$http.get(baseURI).then((response) => {
        if(response.data.datas.data.length > 0){
          var melData=response.data.datas.data[0];
          this.$set(this.forms, "mel", parseInt(melData.value));
        }
       
      });
    },

    asyncRoute(ev) {
      const baseURI =
        this.$settings.endPoint + "rm-route-master?route_name=" + ev;
      this.$http.get(baseURI).then((response) => {
        // this.loading();
        this.fetchRoute = response.data.datas.data;
      });
    },
    loadBranch() {
      const baseURI = this.$settings.endPointCt + "branch";
      this.$http.get(baseURI).then((response) => {
        this.loading();
        this.fetchBranch = response.data.datas.data;
      });
    },
    asyncBranch(ev) {
      const baseURI = this.$settings.endPointCt + "branch?branch_name=" + ev;
      this.$http.get(baseURI).then((response) => {
        // this.loading();
        this.fetchBranch = response.data.datas.data;
      });
    },
    closeMyModal(ev) {
      if (ev !== null) {
        this.toll_route = ev.route;
        // this.forms.tol_route = ev.route.toll_route_code;
        this.$set(this.forms, "toll_price", ev.price);
        // this.forms.toll_fee =   ;
      }
      this.modalRuteTol = false;
     
    },
    
    closeMyModalRateBreak(ev) {
      console.log(ev);
      if(ev!=undefined){
        this.passDataRate=ev;
      }
      
      this.showModalRateBreak=false;
    },


    loadTypeTruck() {
      const baseURI =
        this.$settings.endPointCt + "type-truck";
      this.$http.get(baseURI).then((response) => {
        this.loading();
        this.fetchTypeTruck = response.data.datas.data;
      });
    },
    asyncTypeTruck(ev) {
      const baseURI =
        this.$settings.endPointCt +
        "type-truck?type_truck=" +
        ev;
      this.$http.get(baseURI).then((response) => {
        // this.loading();
        this.fetchTypeTruck = response.data.datas.data;
      });
    },

    
    loadRatio() {
      this.fetchRatio = [];
     
      if (this.branch !== null && this.typeTruck !== null) {
        if(this.RouteList.length > 0 && this.customer!==null){
          let route=this.RouteList[0]?.route_name;
          this.forms.name=route+' '+this.branch.branch_name+ ' '+this.typeTruck.type_truck+' '+this.customer.code;
        }
        this.getMel(this.typeTruck.category);
        this.getPenyebrangan(this.typeTruck.type_truck)
        const baseURI =
          this.$settings.endPoint +
          "rm-ratio-master?branch_name=" +
          this.branch.branch_name +
          "&truck_category=" +
          this.typeTruck.category;
        this.$http.get(baseURI).then((response) => {
          //   this.loading();
          this.fetchRatio = response.data.datas.data;
          if(this.fetchRatio && this.fetchRatio.length > 0){
            this.ratio = response.data.datas.data[0];
            this.fetchRatioDetail = this.ratio.detail;
            this.$set(this.forms, "salary_awal", this.ratio.driver_commision);
            this.$set(this.forms, "self_insurance", this.ratio.driver_self_insurance);
            this.$set(this.forms, "deposit_awal", this.ratio.driver_deposit);
          
            this.$set(this.forms, "nst_biaya_bongkar", 0.01);
            this.$set(this.forms, "alfa_kosongan_bl", 0.01);
            this.$set(this.forms, "nst_insentif_rit1", 0.01);
            this.$set(this.forms, "nst_komisi_supir", 0.01);
            this.$set(this.forms, "nst_biaya_terpal", 0.01);
            this.$set(this.forms, "nst_mel", 0.01);
            this.$set(this.forms, "nst_rekon", 0.01);
            // this.$set(this.forms, "toll_price", 0.01);
            this.$set(this.forms, "", 0.01);

           

            
            if(this.ratio.detail && this.ratio.detail.length == 1){
              this.ratioDetail = this.ratio.detail[0];
            }else if(this.ratio.detail && this.ratio.detail.length == 0){
              this.error("Ratio Detail Empty");
            }
          }else{
            this.error("Ratio Not Found");
          }
        });
      }
    },
    asyncRatio(ev) {
      const baseURI =
        this.$settings.endPoint + "rm-ratio-master?ratio_name=" + ev;
      this.$http.get(baseURI).then((response) => {
        // this.loading();
        this.fetchRatio = response.data.datas.data;
      });
    },
    isNumberLatLong(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46 &&
        charCode !== 45
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    addField() {
      //fieldType.push({ value: "" });c
      this.RouteList.push({
        route: "",
      });
    },
    pickTol() {
      this.modalRouteTolData = {
        ratio: this.ratio,
        toll_route:this.toll_route,
        RouteList:this.RouteList,
      }
      this.modalRuteTol = true;
    },
    resetForm() {
      this.forms = {};
      this.typeTruck = null;
      this.ratio = null;
      this.ratioDetail = null;
      this.RouteList = [];
      this.toll_route = null;
      this.penyebrangan = [];
    },

    backForm() {
      this.params=this.$route.query.params;
      var url="";
      if(this.params=='request'){
        url= "/master-ujo?params=request";
      }else if(this.params=='approval'){
        url = "/master-ujo?params=approval";
      }else{
        url="/master-ujo"
      }
    
      window.location.href =url;
    },

    changeCompany(){
      console.log(this.customer.code);
      this.forms.customer_code=this.customer.code;


        if(this.RouteList.length > 0 && this.customer!==null){
          let route=this.RouteList[0]?.route_name;
          this.forms.name=route+' '+this.branch.branch_name+ ' '+this.typeTruck.type_truck+' '+this.customer.code;
        }

    },

    loadCustomer() {
      
      const baseURI = this.$settings.endPoint + `company/select?company_type=CHAIN`;
      return this.$http.get(baseURI).then((response) => {
        this.fetchCustomer = response.data.datas.data;
      });
    },
    asyncCustomer(ev) {
      const baseURI = this.$settings.endPoint + `company/select?company_type=CHAIN&name=${ev}`;
      this.fetchCustomer = [];
      return this.$http.get(baseURI).then((response) => {
        this.fetchCustomer = response.data.datas.data;
      });
    },

    removeField(index, fieldType) {
      fieldType.splice(index, 1);
    },

    // changeLeadTime(){
    //   var leadtime= this.forms.lead_time;
    //   this.forms.salary=this.forms.salary * leadtime;
    //   if(leadtime > 3){
    //     this.forms.deposit=this.forms.deposit * 3 ;
    //   }else{
    //     this.forms.deposit=this.forms.deposit * leadtime ;
    //   }
    // },

    submitData() {

      this.isLoading = true;

      if (!this.customer || Object.keys(this.customer).length === 0 || !this.customer.company_id) {
        this.error('Customer not found')
        this.isLoading = false;
       
      }else{
        const baseURI = this.$settings.endPoint + "rm-ujo-master/create";
        this.forms.branch_id = this.branch.branch_id;
        this.forms.branch_name = this.branch.branch_name;
        this.forms.customer_id=this.customer.company_id;
        this.forms.customer_code=this.customer.code;
        this.forms.customer_name=this.customer.name;
        this.forms.effective_date=moment(String(this.effective_date.startDate)).format("YYYY-MM-DD");
        this.forms.expired_date=moment(String(this.expired_date.startDate)).format("YYYY-MM-DD");
        // this.forms.status=this.forms.status;
        this.forms.type_truck_id = this.typeTruck.type_truck_id;
        this.forms.type_truck = this.typeTruck.type_truck;
        this.forms.rm_ratio_master_id = this.ratio.rm_ratio_master_id;
        this.forms.ratio_master_name = this.ratio.ratio_name;
        this.forms.ratio_detail_id = this.ratioDetail.rm_ratio_master_detail_id;
        this.forms.ratio_detail_value = this.ratioDetail.ratio;
        // this.forms.fuel_liter = this.getSolarLiter;
        // console.log(this.forms.salary);
        // console.log(this.forms.deposit);
        
        this.forms.deposit=this.getTotalDeposit;

        if(this.forms.type_kalkulasi=='AUTO'){
          this.forms.fuel_price = this.getSolarPrice;
          this.forms.salary=this.getTotalSalary;
        }

        

    
        this.forms.ferry = this.getBiayaPenyebrangan;
        // console.log(this.penyebrangan);
        this.forms.rm_ferry_route_id = this.penyebrangan==null ? '' : this.penyebrangan[0].rm_ferry_route_id;
        this.forms.rm_toll_route_id = this.toll_route?.rm_toll_route_id;
        this.forms.toll_route_name = this.toll_route?.name;
        this.forms.ujo_total = this.getTotalUjo;
        this.forms.ujo_total_rounding = this.getUjoRounding;
        this.forms.route = this.RouteList;
        this.forms.distance = this.getDistance;
        this.forms.rate_break=this.passDataRate;
        
        //console.log(this.forms);
        //this.isLoading = false;
        this.$http
          .post(baseURI, this.forms, { timeout: 10000 })
          .then((response) => {
            
            if (response.data.status === 200) {

              this.params=this.$route.query.params;
              var url="?params";
              if(this.params=='request'){
                url= "?params=request";
              }else if(this.params=='approval'){
                url = "?params=approval";
              }
              this.isLoading = false;
              //this.resetForm();
              this.errors = [];
             
              var params = this.$onRandom(response.data.datas.rm_ujo_master_id);
              window.location.href =
                "/master-ujo/detail/" + params+url + "&status=200&msg=Successfully";
              this.success('Berhasil');
            } else {
              this.isLoading = false;
              this.errors = response.data.errors;
              this.resultError(response.data.errors);
            }
          })
          .catch((error) => {
            this.isLoading = false;
            if (error.code == "ECONNABORTED") {
              this.error("Please Check Your Internet Connection");
            } else {
              if (error.response) {
                if (error.response.status === 422) {
                  this.errors = error.response.data.errors;
                  this.resultError(error.response.data.errors);
                } else if (error.response.status === 500) {
                  this.error(error.response.data.errors.message);
                } else {
                  this.$router.push("/page-not-found");
                }
              }
            }
          });
         }
     
     
    },
    loadActiveFuel() {
      const baseURI = this.$settings.endPoint + "rm-fuel-master/get-active";
      this.$http
        .get(baseURI)
        .then((response) => {
          this.solarPrice = response.data.datas.price;
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.code == "ECONNABORTED") {
            this.error("Please Check Your Internet Connection");
          } else {
            if (error.response) {
              if (error.response.status === 422) {
                this.$swal({
                  title: "Warning !!",
                  text: "Solar Price Empty",
                  icon:"warning",

                });
                // this.errors = error.response.data.errors;
                // this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.error(error.response.data.errors.message);
              } else {
                this.$router.push("/page-not-found");
              }
            }
          }
        });
    },
    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.fade(false);
      }, 1000); // hide the message after 3 seconds
    },

    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      // this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function (permision) {
        if (permision.permission.controller == "RmUjoMasterController") {
          rolePermision = permision;
          // console.log(permision);
        }
      });

      if (!rolePermision.method_create) {
        this.$router.push("/authorized-error");
      }

      this.permision_role = rolePermision;
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },


    handleChange() {
      console.log(this.forms.type_ujo);
      if(this.forms.type_ujo==='A'){
        this.disabledCs=true;
        this.customer={
            name:"--ALL--",
            code:"ALL",
            company_id:1,
        }
        this.forms.customer_code="ALL"
      }else{
        this.disabledCs=false;
        this.customer="";
        this.forms.customer_code=""
      }


      if(this.RouteList.length > 0 ){
          let route=this.RouteList[0]?.route_name;
          this.forms.name=route+' '+this.branch.branch_name+ ' '+this.typeTruck.type_truck+' '+this.customer?.code;
      }
  
     
    },


    handleChangeKalkulasi() {
      console.log(this.forms.type_kalkulasi);
      if(this.forms.type_kalkulasi==='MANUAL'){
       this.solarPrice=0;
      }else{
        this.loadActiveFuel();
      }     
    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },
  },
  events: {},
  created: function () {},
  mounted() {
    this.fetchIt();
    this.loadRoute();
    this.loadTypeTruck();
    this.loadBranch();
    this.loadRatio();
    this.loadActiveFuel();
    this.loadCustomer();


    const formElement = this.$refs.formElement;
    if (formElement) {
      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if (mutation.type === 'attributes' && mutation.attributeName === 'readonly') {
            const inputs = formElement.querySelectorAll('.multiselect__input');
            inputs.forEach(input => {
              input.removeAttribute('readonly');  // Hapus readonly setiap kali diubah
            });
          }
        });
      });

      const inputs = formElement.querySelectorAll('.multiselect__input');
      inputs.forEach(input => {
        observer.observe(input, { attributes: true });
      });
    }

  },
};
</script>
<style scoped>
button.gm-ui-hover-effect {
  visibility: hidden;
}

.blur-content {
  filter: blur(5px);
  pointer-events: none;
}
</style>
