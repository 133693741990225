<template>
  <div>
    <div class="loader" v-if="this.isLoading"></div>
    <div class="loadertext" v-if="this.isLoading">
      {{ $t("loadingTxt") }}...
    </div>
    <div class="main-wrapper" :class="{ 'blur-content': this.isLoading }">
      <!--begin::sidebar-->
      <sidebar-component classMenu="BranchAdd"></sidebar-component>
      <!--end::sidebar-->

      <div class="page-wrapper">
        <!--begin::navbar-->
        <navbar-component></navbar-component>
        <!--end::navbar-->

        <!--begin::content-->
        <div class="page-content">
          <div class="d-flex justify-content-between align-items-center flex-wrap grid-margin">
            <div class="col-lg-12 col-xl-12 col-sm-12 col-xs-12 col-md-12">
              <div class="card">
                <div class="card-header">
                  <!-- <i class="link-icon float-start" data-feather="arrow-left"></i> -->
                  &nbsp;&nbsp;&nbsp;
                  <h6 class="card-title mb-0 float-start" style="padding-left: 25px">
                    {{ $t("route_add") }} {{ $route.query.params }}
                  </h6>
                  <!-- <button
                    v-if="!isMaps"
                    class="btn btn-success float-end"
                    type="button"
                    style="margin-right: 20px"
                    @click="onClickShowMaps(true)"
                  >
                    <i class="mdi mdi-google-maps"></i>
                    Show Maps
                  </button>
                  <button
                    v-if="isMaps"
                    class="btn btn-danger float-end"
                    type="button"
                    style="margin-right: 20px"
                    @click="onClickShowMaps(false)"
                  >
                    <i class="mdi mdi-google-maps"></i>
                    Hide Maps
                  </button> -->
                  <button class="btn btn-default btn-sm float-end" @click="resetForm" type="button">
                    <i class="link-icon" data-feather="repeat"></i>
                    {{ $t("resetFormTxt") }}
                  </button>
                </div>
                <div class="card-body">
                  <!--begin::loading-data-->
                  <div v-if="isLoading" class="d-flex align-items-center">
                    <div class="spinner-border ms-auto" role="status" aria-hidden="true"></div>
                    <strong>{{ $t("loadingTxt") }}...</strong>
                  </div>
                  <!--end::loading-data-->

                  <form class="forms-sample" @submit.prevent="submitData" method="POST">
                    <div class="d-flex justify-content-between align-items-baseline mb-2"></div>

                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">{{
                          $t("route_master_code") }}</label>
                      </div>
                      <div class="col-lg-8">
                        <input type="text" class="form-control" id="route_master_code"
                          :placeholder="$t('route_master_code')" v-model="forms.route_master_code" disabled />
                        <div v-if="errors.route_master_code">
                          <div v-for="error in errors.route_master_code" :key="error" class="alert alert-primary"
                            role="alert">
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>



                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">User
                          Request</label>
                      </div>
                      <div class="col-lg-3">
                        <div class="form-check form-check-inline">
                          <input type="radio" value="BD" class="form-check-input" v-model="forms.user_request"
                            id="radioC">
                          <label class="form-check-label" for="radioC">
                            BD
                          </label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input type="radio" value="User" class="form-check-input" v-model="forms.user_request"
                            id="radioD">
                          <label class="form-check-label" for="radioD">
                            User
                          </label>
                        </div>
                        <div v-if="errors.user_request">
                          <div v-for="error in errors.user_request" :key="error" class="alert alert-primary"
                            role="alert">
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Haul<span
                            class="wajib">*</span></label>
                      </div>
                      <div class="col-lg-3">
                        <div class="form-check form-check-inline">
                          <input type="radio" value="SHORT HAUL" class="form-check-input" v-model="forms.haul"
                            id="radioA">
                          <label class="form-check-label" for="radioA">
                            SHORT HAUL
                          </label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input type="radio" value="LONG HAUL" class="form-check-input" v-model="forms.haul"
                            id="radioB">
                          <label class="form-check-label" for="radioB">
                            LONG HAUL
                          </label>
                        </div>
                        <div v-if="errors.haul">
                          <div v-for="error in errors.haul" :key="error" class="alert alert-primary" role="alert">
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>




                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">{{ $t("route_name")
                          }} <span class="wajib">*</span></label>
                      </div>
                      <div class="col-lg-8">
                        <input type="text" class="form-control" id="route_name" :placeholder="$t('route_name')"
                          v-model="forms.route_name" required readonly />

                        <div v-if="errors.route_name">
                          <div v-for="error in errors.route_name" :key="error" class="alert alert-primary" role="alert">
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">{{ $t("pool_start")
                          }}</label>
                      </div>
                      <div class="col-lg-8">
                        <v-select :options="fetchPool" v-model="forms.pool_start_name"></v-select>
                        <div v-if="errors.pool_start_name">
                          <div v-for="error in errors.pool_start_name" :key="error" class="alert alert-primary"
                            role="alert">
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>

                   

                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">{{
                          $t("route_start") }}</label>
                      </div>
                      <div class="col-lg-10">

                        <div class="row">
                          <div class="col-sm-3">
                           
                            <v-select :options="fetchLocation" id="location" v-model="route_origin"
                              @search="asyncSearchLocation" label="name" placeholder="Stop Location"
                              @input="routeOriginSelected($event)">
                            </v-select>
                          </div>
                          <div class="col-sm-3">
                            <!-- <v-select
                              :options="fetchProvince"
                              id="origin_province"
                              v-model="forms.province_origin"
                              @search="asyncProvince"
                              label="province"
                              :reduce="province=> province.province"
                              placeholder="Select Province"
                              @input="provinceSelected('origin',$event)"
                            >
                            </v-select> -->

                            <input type="text" :value="forms.province_origin" readonly class="form-control">

                          </div>
                          <div class="col-sm-3">
                            <input type="text" :value="forms.city_origin" readonly class="form-control">
                            <!-- <v-select
                            id="city-origin"
                              :options="fetchCityOri"
                              label="city"
                              placeholder="Select City"
                              v-model="forms.city_origin" 
                              :reduce="city=> city.city"
                              @input="citySelected('origin',$event)"
                            >

                            </v-select> -->
                            <!-- <v-select
                              
                             
                            >
                            </v-select> -->
                          </div>
                          <!-- <div class="col-sm-3">
                            <v-select
                              :options="fetchDistrictOri"
                              label="district"
                              placeholder="Select District"
                              v-model="forms.route_origin"
                              :reduce="district=> district.district"
                            >
                            </v-select>
                          </div> -->
                          <div class="col-sm-3">
                            <input type="text" :value="forms.pick_state" readonly class="form-control">
                          </div>
                        </div>
                        <div v-if="errors.route_origin">
                          <div v-for="error in errors.route_origin" :key="error" class="alert alert-primary"
                            role="alert">
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- <div class="row mb-3">
                      <div class="col-lg-2">
                        <label
                          for="defaultconfig"
                          class="col-form-label"
                          style="padding-left: 25px"
                          >{{ $t("coordinate_start") }}</label
                        >
                      </div>
                      <div class="col-lg-8">
                        <input
                          type="text"
                          class="form-control"
                          id="origin_coordinate"
                          :placeholder="$t('coordinate_start')"
                          v-model="forms.origin_coordinate"
                          disabled
                        />
                        <div v-if="errors.origin_coordinate">
                          <div
                            v-for="error in errors.origin_coordinate"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-1">
                        <button
                          :class="{
                            'btn-primary': setMarker != 'origin',
                            'btn-warning': setMarker == 'origin',
                          }"
                          class="btn btn-sm"
                          @click="setMapMarker('origin')"
                          type="button"
                        >
                          Set Marker
                        </button>
                      </div>
                    </div> -->

                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">{{ $t("route_end")
                          }}</label>
                      </div>
                      <div class="col-lg-10">

                        <div class="row">
                          <div class="col-sm-3">
                            <v-select :options="fetchLocation" id="location" v-model="route_destination"
                              @search="asyncSearchLocation" label="name" placeholder="Stop Location"
                              @input="routeDestinationSelected($event)">
                            </v-select>
                          </div>
                          <div class="col-sm-3">
                            <!-- <v-select
                              :options="fetchProvince"
                              id="origin_province"
                              v-model="forms.province_origin"
                              @search="asyncProvince"
                              label="province"
                              :reduce="province=> province.province"
                              placeholder="Select Province"
                              @input="provinceSelected('origin',$event)"
                            >
                            </v-select> -->

                            <input type="text" :value="forms.province_destination" readonly class="form-control">

                          </div>
                          <div class="col-sm-3">
                            <input type="text" :value="forms.city_destination" readonly class="form-control">
                            <!-- <v-select
                            id="city-origin"
                              :options="fetchCityOri"
                              label="city"
                              placeholder="Select City"
                              v-model="forms.city_origin" 
                              :reduce="city=> city.city"
                              @input="citySelected('origin',$event)"
                            >

                            </v-select> -->
                            <!-- <v-select
                              
                             
                            >
                            </v-select> -->
                          </div>
                          <!-- <div class="col-sm-3">
                            <v-select
                              :options="fetchDistrictOri"
                              label="district"
                              placeholder="Select District"
                              v-model="forms.route_origin"
                              :reduce="district=> district.district"
                            >
                            </v-select>
                          </div> -->
                          <div class="col-sm-3">
                            <input type="text" :value="forms.drop_state" readonly class="form-control">
                          </div>
                        </div>
                        <div v-if="errors.route_origin">
                          <div v-for="error in errors.route_origin" :key="error" class="alert alert-primary"
                            role="alert">
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>


                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">{{ $t("pool_end")
                          }}</label>
                      </div>
                      <div class="col-lg-8">
                       
                        <v-select :options="fetchPool" v-model="forms.pool_end_name" @input="changeNameRoute"></v-select>
                        <div v-if="errors.pool_end_name">
                          <div v-for="error in errors.pool_end_name" :key="error" class="alert alert-primary"
                            role="alert">
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>



                    <!-- <div class="row mb-3">
                      <div class="col-lg-2">
                        <label
                          for="defaultconfig"
                          class="col-form-label"
                          style="padding-left: 25px"
                          >{{ $t("coordinate_end") }}</label
                        >
                      </div>
                      <div class="col-lg-8">
                        <input
                          type="text"
                          class="form-control"
                          id="destination_coordinate"
                          :placeholder="$t('coordinate_end')"
                          v-model="forms.destination_coordinate"
                          disabled
                        />
                        <div v-if="errors.destination_coordinate">
                          <div
                            v-for="error in errors.destination_coordinate"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-1">
                        <button
                          class="btn btn-sm"
                          :class="{
                            'btn-primary': setMarker != 'destination',
                            'btn-warning': setMarker == 'destination',
                          }"
                          @click="setMapMarker('destination')"
                          type="button"
                        >
                          Set Marker
                        </button>
                      </div>
                    </div> -->
                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Lead Time <span
                            class="wajib">*</span></label>
                      </div>
                      <div class="col-lg-2">
                        <!-- <input type="number" class="form-control" id="lead time" placeholder="Lead time"
                          v-model.number="forms.leadtime" required min="1" /> -->
                          <input
                            type="text"
                            class="form-control"
                            id="lead time"
                            placeholder="Lead time"
                            v-model="forms.leadtime"
                            @input="validateInput"
                            required
                          />
                        <div v-if="errors.leadtime">
                          <div v-for="error in errors.leadtime" :key="error" class="alert alert-primary" role="alert">
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-2">
                        <b style="margin-top: 20px">Hari</b>
                      </div>
                    </div>
                    <!-- <div class="row mb-3">
                      <div class="col-lg-2">
                        <label
                          for="defaultconfig"
                          class="col-form-label"
                          style="padding-left: 25px"
                          >Lead Time POD </label
                        >
                      </div>
                      <div class="col-lg-2">
                        <input
                          type="number"
                          class="form-control"
                          id="lead time"
                          placeholder="Lead time POD"
                          v-model.number="forms.leadtime_pod"
                         
                          min="1"
                        />
                        <div v-if="errors.leadtime_pod">
                          <div
                            v-for="error in errors.leadtime_pod"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-2">
                        <b style="margin-top: 20px">Hari</b>
                      </div>
                    </div> -->


                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">{{
                          $t("pooltoloading") }} <span class="wajib">*</span></label>
                      </div>
                      <div class="col-lg-2">
                        <input type="number" class="form-control" id="pooltoloading" :placeholder="$t('pooltoloading')"
                          v-model.number="forms.pooltoloading" step="any" required min="0" />
                        <div v-if="errors.pooltoloading">
                          <div v-for="error in errors.pooltoloading" :key="error" class="alert alert-primary"
                            role="alert">
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-2">
                        <b style="margin-top: 20px">Km</b>
                      </div>
                    </div>



                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">{{
                          $t("loadtounload") }} <span class="wajib">*</span></label>
                      </div>
                      <div class="col-lg-2">
                        <input type="number" class="form-control" id="loadtounload" :placeholder="$t('loadtounload')"
                          v-model.number="forms.loadtounload" step="any" required min="1" />
                        <div v-if="errors.loadtounload">
                          <div v-for="error in errors.loadtounload" :key="error" class="alert alert-primary"
                            role="alert">
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-2">
                        <b style="margin-top: 20px">Km</b>
                      </div>
                    </div>


                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">{{
                          $t("unloadtopool") }} <span class="wajib">*</span></label>
                      </div>
                      <div class="col-lg-2">
                        <input type="number" class="form-control" id="unloadtopool" :placeholder="$t('unloadtopool')"
                          v-model.number="forms.unloadtopool" step="any" required min="0" />
                        <div v-if="errors.unloadtopool">
                          <div v-for="error in errors.unloadtopool" :key="error" class="alert alert-primary"
                            role="alert">
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-2">
                        <b style="margin-top: 20px">Km</b>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">{{ $t("distance")
                          }} Total<span class="wajib">*</span></label>
                      </div>
                      <div class="col-lg-2">

                        <input type="text" class="form-control" id="distance" :placeholder="$t('distance')"
                          :value="getDistance" disabled />


                        <div v-if="errors.distance">
                          <div v-for="error in errors.distance" :key="error" class="alert alert-primary" role="alert">
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-2">
                        <b style="margin-top: 20px">Km</b>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Margin (%)</label>
                      </div>
                      <div class="col-lg-8">

                        <input type="number" class="form-control" id="distance" placeholder="Margin" step="any" min="1"
                          v-model.number="forms.margin" />
                        <div v-if="errors.margin">
                          <div v-for="error in errors.margin" :key="error" class="alert alert-primary" role="alert">
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">{{ $t("distance")
                          }} + Margin</label>
                      </div>
                      <div class="col-lg-8">
                        <input type="text" class="form-control" id="distance" :placeholder="$t('distance') + ' Margin'"
                          :value="getDistanceMargin" disabled />
                        <div v-if="errors.margin">
                          <div v-for="error in errors.margin" :key="error" class="alert alert-primary" role="alert">
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>



                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Pool to Load
                          Link</label>
                      </div>
                      <div class="col-lg-8">
                        <input type="text" class="form-control" id="distance" v-model="forms.pooltoload_link" />
                        <div v-if="errors.pooltoload_link">
                          <div v-for="error in errors.pooltoload_link" :key="error" class="alert alert-primary"
                            role="alert">
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>



                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Load To Unload
                          Link</label>
                      </div>
                      <div class="col-lg-8">
                        <input type="text" class="form-control" id="distance" v-model="forms.loadtounload_link" />
                        <div v-if="errors.loadtounload_link">
                          <div v-for="error in errors.loadtounload_link" :key="error" class="alert alert-primary"
                            role="alert">
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>



                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Unload To Pool
                          Link</label>
                      </div>
                      <div class="col-lg-8">
                        <input type="text" class="form-control" id="distance" v-model="forms.unloadtopool_link" />
                        <div v-if="errors.unloadtopool_link">
                          <div v-for="error in errors.unloadtopool_link" :key="error" class="alert alert-primary"
                            role="alert">
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Pool to Load Link Default</label>
                      </div>
                      <div class="col-lg-8">
                        <input type="text" class="form-control" id="distance" v-model="forms.pooltoload_link_default" />
                        <div v-if="errors.pooltoload_link_default">
                          <div v-for="error in errors.pooltoload_link_default" :key="error" class="alert alert-primary"
                            role="alert">
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>


                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Load to Unload Link Default</label>
                      </div>
                      <div class="col-lg-8">
                        <input type="text" class="form-control" id="distance" v-model="forms.loadtounload_link_default" />
                        <div v-if="errors.loadtounload_link_default">
                          <div v-for="error in errors.loadtounload_link_default" :key="error" class="alert alert-primary"
                            role="alert">
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>


                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Unload to Pool Link Default</label>
                      </div>
                      <div class="col-lg-8">
                        <input type="text" class="form-control" id="distance" v-model="forms.unloadtopool_link_default" />
                        <div v-if="errors.unloadtopool_link_default">
                          <div v-for="error in errors.unloadtopool_link_default" :key="error" class="alert alert-primary"
                            role="alert">
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Upload File</label>
                      </div>
                      <div class="col-lg-8">
                        <div v-if="photo_temporary" class="dropify-wrapper has-preview">
                          <div class="dropify-message">
                            <span class="file-icon" v-if="!photo_temporary">
                              <p>
                                Drag and drop a file here or click
                              </p>
                            </span>
                            <p class="dropify-error">
                              Ooops, something wrong appended.
                            </p>
                          </div>
                          <div class="dropify-loader" style="display: none"></div>
                          <div class="dropify-errors-container">
                            <ul></ul>
                          </div>
                          <input type="file" ref="images" accept="image/*" class="form-control"
                            @change="handleFileUpload($event)" />
                          <button type="button" class="dropify-clear" @click="removeImages">
                            Remove
                          </button>
                          <div class="dropify-preview" style="display: block">
                            <img :src="photo_temporary" width="90%" height="80%" />
                            <span class="dropify-render"></span>
                            <div class="dropify-infos">
                              <div class="dropify-infos-inner">
                                <p class="dropify-filename"></p>
                                <p class="dropify-infos-message">
                                  Drag and drop or click to replace
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div v-else class="dropify-wrapper">
                          <div class="dropify-message">
                            <span class="file-icon">
                              <p>
                                Drag and drop a file here or click
                              </p>
                            </span>
                          </div>
                          <div class="dropify-loader" style="display: none"></div>
                          <div class="dropify-errors-container">
                            <ul></ul>
                          </div>
                          <input type="file" ref="images" accept="image/*" class="form-control"
                            @change="handleFileUpload($event)" />
                          <button type="button" class="dropify-clear">
                            Remove
                          </button>
                          <div class="dropify-preview" style="display: none">
                            <img :src="photo_temporary" width="90%" height="80%" />
                            <span class="dropify-render"></span>
                            <div class="dropify-infos">
                              <div class="dropify-infos-inner">
                                <p class="dropify-filename"></p>
                                <p class="dropify-infos-message">
                                  Drag and drop or click to replace
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div v-if="errors.picture">
                          <div v-for="error in errors.picture" :key="error" class="alert alert-primary" role="alert">
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- <div class="row" v-if="isMaps">
                      <div class="col-sm-12">
                        <div class="card">
                          <div class="card-header">
                            <h5>Maps</h5>
                          </div>

                          <div class="card-body">
                            <div class="row mb-3">
                              <div class="col-sm-6">
                                <div class="row"></div>
                              </div>
                            </div>
                            <div class="row mb-2">
                              <div class="col-sm-12">
                                <GmapAutocomplete
                                  class="form-control"
                                  placeholder="Search Location"
                                  @place_changed="setPlace"
                                >
                                </GmapAutocomplete>
                              </div>
                            </div>
                           <div class="row">
                              <div class="col-sm-12">
                                <div class="mb-3">
                                  <gmap-map
                                    :zoom="mapZoom"
                                    :center="center"
                                    style="width: 100%; height: 400px"
                                    map-type-id="terrain"
                                    @click="clickMap"
                                    ref="currentMaps"
                                  >
                                    <gmap-marker
                                      :key="index"
                                      v-for="(gmp, index) in markers"
                                      :position="gmp.position"
                                      @click="center = gmp.position"
                                    >
                                      <gmap-info-window :opened="true">
                                        <span
                                          class="badge"
                                          :style="{
                                            'background-color': 'white',
                                            color: 'black',
                                          }"
                                          :opened="false"
                                        >
                                          {{ gmp.position.label }}
                                        </span>
                                      </gmap-info-window>
                                    </gmap-marker>

                                    <DirectionsRenderer
                                      @distanceData="distanceData"
                                      travelMode="DRIVING"
                                      :origin="startLocation"
                                      :destination="endLocation"
                                      :waypoints="waypnt"
                                    />
                                  </gmap-map>
                                </div>
                              </div>
                            </div> 
                          </div>
                        </div>
                      </div>
                    </div> -->

                    <div class="card-footer">
                      <button class="btn btn-warning text-black fw-bolder btn-icon-text" @click="backForm"
                        type="button">
                        <i class="link-icon" data-feather="arrow-left"></i>
                        {{ $t("backMess") }}
                      </button>
                      &nbsp;&nbsp;&nbsp;
                      <button class="btn btn-primary float-end btn-sm" type="submit">
                        <i class="link-icon" data-feather="save"></i>
                        {{ $t("submitFormTxt") }}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <!--end::card-->
            </div>
          </div>
        </div>
        <!--end::content-->

        <!--begin::footer-->
        <footer-component></footer-component>
        <!--end::footer-->
      </div>
    </div>
  </div>
</template>

<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";
// import multiselect from "vue-multiselect";
// import DirectionsRenderer from "@/middleware/DirectionsRenderedDynamic";

// import myMixinDistance from "@/middleware/getDistance";
// import GMapAutocomplete from "/vue2-google-maps/src/components"

export default {
  name: "BranchAdd",
  props: {},
  // mixins: [myMixinDistance],
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,
    // "multiselect":multiselect
    // DirectionsRenderer,
  },
  data() {
    const mapMarkerIcon = require("../../../assets/icontruck.png");
    return {
      route_origin: "",
      route_destination: "",
      fetchLocation: [],
      pick_sl: [],
      isLoading: false,
      markerOption: {
        url: mapMarkerIcon,
        size: { width: 30, height: 30, f: "20px", b: "20px" },
        scaledSize: { width: 30, height: 30, f: "20px", b: "20px" },
        rotation: 45,
      },
      markers: [],
      center: {
        lat: -2.548926,
        lng: 118.0148634,
      },
      startLocation: null,
      endLocation: null,
      // waypnt: [],

      poolStart: [],
      poolEnd: [],
      fetchPool: [],
      fetchCityOri: [],
      fetchCityDest: [],
      fetchDistrictOri: [],
      fetchDistrictDest: [],
      fetchProvince: [],
      setMarker: null,

      mapSearch: "",
      suggestions: [],
      mapZoom: 8,
      maxToasts: 100,
      position: "up right",
      closeBtn: true,
      errors: [],
      poolAll: [],
      permision_role: [],
      userData: "",
      isMaps: true,
      companyCode: "",
      forms: {
        poolToLoading: 0,
        loadToUnload: 0,
        unloadToPool: 0,
        distance: 1,
        origin_coordinate: "",
        destination_coordinate: "",
        margin: 10,
        route_destination: null,
        drop_state: null,
        route_origin: null,
        pick_state: null,
        city_origin: null,
        province_origin: null,
        city_destination: null,
        province_destination: null,
      },
      photo_temporary: null,
      photo: "",
      mapClick: 1,
    };
  },
  computed: {


    getDistanceMargin() {
      let value = 0;
      if (this.forms.margin && this.getDistance) {
        value =
          (this.getDistance * this.forms.margin) / 100 + this.getDistance;
      }
      return value;
    },

    getDistance() {
      let poolToLoading = parseFloat(this.forms.pooltoloading) || 0;
      let loadToUnload = parseFloat(this.forms.loadtounload) || 0;
      let unloadToPool = parseFloat(this.forms.unloadtopool) || 0;

      return poolToLoading + loadToUnload + unloadToPool;
    }

  },
  watch: {},
  methods: {

    onSelectPick(ev) {

      console.log(ev)
    },


    routeOriginSelected(ev) {
    

      this.forms.province_origin = ev.province
      this.forms.city_origin = ev.city
      this.forms.pick_state = ev.state

    
     
    },

    routeDestinationSelected(ev) {
    
      
  
      this.forms.province_destination = ev.province
      this.forms.city_destination = ev.city
      this.forms.drop_state = ev.state


     

    },

    changeNameRoute(){
      this.forms.route_name=this.forms.pool_start_name+' '+this.route_origin?.name+'-'+this.route_destination?.name+' '+this.forms.pool_end_name;
     
    },


    provinceSelected(loc, ev) {
      if (loc == 'origin') {
        this.forms.route_origin = null
        this.forms.city_origin = null
      }
      if (loc == 'dest') {
        this.forms.route_destination = null
        this.forms.city_destination = null
      }
      this.loadState(loc, ev);
      this.loadCity(ev, loc);
    },
    citySelected(loc, ev) {
      if (loc == 'origin') {
        this.forms.route_origin = null
      }
      if (loc == 'dest') {
        this.forms.route_destination = null
      }
      this.loadDistrict(loc, ev);
    },
    loadProvince() {
      const baseURI = this.$settings.endPoint + "postal-code/select-province/indonesia";
      return this.$http.get(baseURI).then((response) => {
        this.fetchProvince = response.data.datas.data;
      });
    },

    validateInput(event) {
      const value = event.target.value;
      // Hanya izinkan angka, koma, dan titik
      if (!/^[0-9,.]*$/.test(value)) { // Tidak perlu escape karakter di dalam [ ]
        this.forms.leadtime = value.replace(/[^0-9,.]/g, ''); // Tidak perlu escape karakter di sini
        this.errors.leadtime = ['Hanya angka, koma, dan titik yang diperbolehkan.'];
      } else {
        this.errors.leadtime = [];
      }
    },



    asyncProvince(ev) {
      const baseURI = this.$settings.endPoint + "postal-code/select-province/indonesia?province=" + ev;
      return this.$http.get(baseURI).then((response) => {
        this.fetchProvince = response.data.datas.data;
      });
    },
    loadCity(ev, loc) {
      if (ev) {
        const baseURI = this.$settings.endPoint + "postal-code/select-city/" + ev;
        return this.$http.get(baseURI).then((response) => {
          if (loc == 'origin') {
            this.fetchCityOri = response.data.datas;
          }
          if (loc == 'dest') {
            this.fetchCityDest = response.data.datas;
          }
        });
      }
    },


    customLabelLocation(ev) {
      return ev.name + ' - ' + ev.int_code;
    },

    handleChange() {
      var total = this.forms.pooltoloading + this.forms.loadtounload + this.forms.unloadtopool
      this.forms.distance = total;
    },
    loadState(loc, ev) {
      const baseURI = this.$settings.endPoint + "postal-code?province=" + ev;
      return this.$http.get(baseURI).then((response) => {
        let data = response.data.datas.data;
        if (data && data.length > 0) {
          if (loc == 'dest') {
            this.$set(this.forms, 'drop_state', data[0].state);
          }
          if (loc == 'origin') {
            this.$set(this.forms, 'pick_state', data[0].state);
          }
        }
      });
    },
    loadDistrict(loc, ev) {
      const baseURI = this.$settings.endPoint + "postal-code/select-district/" + ev;
      return this.$http.get(baseURI).then((response) => {
        if (loc == 'origin') {
          this.fetchDistrictOri = response.data.datas;
        }
        if (loc == 'dest') {
          this.fetchDistrictDest = response.data.datas;
        }
      });
    },
    distanceData(ev) {
      console.log(ev);
      this.forms.distance = ev / 1000;
      // console.log(ev);
    },

    removeImages() {
      this.$refs.photo = "";
      this.photo_temporary = null;
    },

    handleFileUpload(event) {
      this.photo = event.target.files[0];
      this.photo_temporary = URL.createObjectURL(this.photo);
    },


    isNumberLatLong(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46 &&
        charCode !== 45
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    setMapMarker(type) {
      if (this.setMarker == type) {
        this.setMarker = null;
      } else {
        this.setMarker = type;
      }
    },
    resetForm() {
      console.log(this.forms);
      // this.forms.branch_name = "";
      // this.forms.description = "";
      // this.forms.sequence = "";
      // this.forms.value = "";
    },
    clickMap(ev) {
      console.log(ev);
      if (this.setMarker !== null) {
        if (this.setMarker == "origin") {
          this.forms.origin_coordinate =
            ev.latLng.lat() + ", " + ev.latLng.lng();
        }
        if (this.setMarker == "destination") {
          this.forms.destination_coordinate =
            ev.latLng.lat() + ", " + ev.latLng.lng();
        }
      }

      this.setMarker = null;
    },


    backForm() {
      this.params = this.$route.query.params;
      var url = "";
      if (this.params == 'request') {
        url = "/rute?params=request";
      } else if (this.params == 'approval') {
        url = "/rute?params=approval";
      } else {
        url = "/rute"
      }

      window.location.href = url;
    },

    setOriginCordinate() {
      console.log("Origin Changed !");
      if (this.poolStart && this.poolStart !== "") {
        var markerPickup = {
          lat: this.poolStart.pool_latitude,
          lng: this.poolStart.pool_longitude,
          label: this.$t("coordinate_start"),
        };
        this.center = markerPickup;
        this.startLocation = markerPickup;
      }
    },
    setDestinationCordinate() {
      if (this.poolEnd && this.poolEnd !== "") {
        var markerDestination = {
          lat: this.poolEnd.pool_latitude,
          lng: this.poolEnd.pool_longitude,
          label: this.$t("coordinate_end"),
        };
        this.endLocation = markerDestination;
      }
    },


    loadLocation() {

      const baseURI = this.$settings.endPoint + `location`;
      return this.$http.get(baseURI).then((response) => {
        this.fetchLocation = response.data.datas.data;
        console.log(response.data.datas.data);

      });
    },

    asyncSearchLocation(ev) {
      this.fetchLocation = [];
      const baseURI = this.$settings.endPoint + `location?name=` + ev;

      return this.$http.get(baseURI).then((response) => {
        this.fetchLocation = response.data.datas.data;
      });
    },

    submitData() {
      this.isLoading = true;
      // this.forms.pool_start = this.poolStart.master_pool_id;
      // this.forms.pool_start_name = this.poolStart;
      // this.forms.pool_end = this.poolEnd.master_pool_id;
      // this.forms.pool_end_name = this.poolEnd.pool_name;
      this.forms.distance_margin = this.getDistanceMargin;
      this.forms.distance = this.getDistance;
      this.forms.picture = this.photo;
      this.forms.route_origin = this.route_origin?.name;
      this.forms.route_destination = this.route_destination?.name;
      this.forms.route_state=this.forms.pick_state+'-'+this.forms.drop_state;

      let formData = new FormData();

      Object.keys(this.forms).forEach((key) => {
        formData.append(key, this.forms[key]);
      });



      const baseURI = this.$settings.endPoint + "rm-route-master/create";
      this.$http
        .post(baseURI, formData, { timeout: 10000 })
        .then((response) => {
          this.isLoading = false;
          if (response.data.status === 200) {

            this.errors = [];
            console.log(response.data.datas);
            var params = this.$onRandom(response.data.datas.rm_route_master_id);

            window.location.href =
              "/rute/detail/" + params + "?status=200&msg=Successfully";
            this.success("Berhasil");
          } else {
            this.errors = response.data.errors;
            this.resultError(response.data.errors);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.code == "ECONNABORTED") {
            this.error("Please Check Your Internet Connection");
          } else {
            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.error(error.response.data.errors.message);
              } else {
                this.$router.push("/page-not-found");
              }
            }
          }
        });
    },
    asyncPool(ev) {
      const baseURI =
        this.$settings.endPointCt + `master-lookup?unique_id=mpool&value=${ev}`;
      return this.$http.get(baseURI).then((response) => {
        this.fetchPool = response.data.datas;
      });

    },
    setPlace(field, ev) {
      if (ev.geometry.viewport) {
        this.$refs.currentMaps.fitBounds(ev.geometry.viewport);
        // console.log(this.$refs.currentMaps)
        // console.log(this.$refs.currentMaps.fitBounds)
      } else {
        this.center = {
          lat: ev.geometry.location.lat(),
          lng: ev.geometry.location.lng(),
        };
        this.mapZoom = 15;
      }
      // this.center = {
      //   lat: ev.geometry.location.lat(),
      //   lng: ev.geometry.location.lng(),
      // };
      // this.mapZoom = 20;
      if (field == "origin") {
        this.forms.route_origin = ev.name;
      }
      if (field == "destination") {
        this.forms.route_destination = ev.name;
      }
      console.log(ev);
    },
    loadPool() {
      const baseURI =
        this.$settings.endPointCt + `master-lookup?unique_id=mpool`;
      return this.$http.get(baseURI).then((response) => {
        this.fetchPool = response.data.datas;
      });
    },
    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.fade(false);
        [];
      }, 1000); // hide the message after 3 seconds
    },

    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      // this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function (permision) {
        if (permision.permission.controller == "RmRouteMasterController") {
          rolePermision = permision;
          // console.log(permision);
        }
      });

      if (!rolePermision.method_create) {
        this.$router.push("/authorized-error");
      }

      this.permision_role = rolePermision;
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },
  },
  events: {},
  created: function () { },
  mounted() {
    this.fetchIt();
    this.loadPool();
    this.loadProvince();
    this.loadLocation();
  },
};
</script>
<style scoped>
button.gm-ui-hover-effect {
  visibility: hidden;
}

.blur-content {
  filter: blur(5px);
  pointer-events: none;
}

.suggestions {
  list-style: none;
  padding: 0;
  border: 1px solid #ccc;
  max-height: 150px;
  overflow-y: auto;
}

.suggestions li {
  padding: 5px;
  cursor: pointer;
  background-color: #fff;
}

.suggestions li:hover {
  background-color: #f5f5f5;
}

.dropify-wrapper {
  height: 500px;
}
</style>
